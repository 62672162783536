import React, { useEffect } from "react";
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";
import strings from "../../strings";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { type_input_p } from "../../utils/constants";
import config from "../../config";

import BlockComponent from "../../components/BlockComponent/BlockComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TopComponent from "../../components/TopComponent/TopComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import InputComponent from "../../components/InputComponent/InputComponent";
import HowItWorksComponent from "../../components/HowItWorksComponent/HowItWorksComponent";
import BackIconComponent from "../../components/BackIconComponent/BackIconComponent";
import KeyboardComponent from "../../components/Keyboard";

const { test } = config;

export default (props) => {
  const history = useHistory();
  //reset
  useEffect(() => {
    props.lockerStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // states.
  const [loading, setLoading] = useState(false);
  const [keyboard, setKeyboard] = useState(false);
  const [error, setError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [codeOrder, setCodeOrder] = useState("");
  const [popupError, setPopupError] = useState("");

  // methods.
  const handleScan = async (result) => {
    if (result && !loading) {
      setLoading(true);
      setError(false);
      const { success, letter, error } = await props.lockerStore.checkCode(
        result
      );
      if (success) {
        history.push(
          letter === type_input_p
            ? "/order"
            : props.lockerStore.hasReversesOrder()
            ? "/reverse"
            : "/supply"
        );
      } else {
        if (keyboard) {
          setMessageError(error);
        } else {
          setPopupError(error);
        }
        handleError();
      }
    }
  };

  const handleError = (error) => {
    setLoading(false);
    setError(true);
  };

  const closeError = () => {
    setPopupError(false);
    setError(false);
  };

  const informPin = () => {
    // digitar pin abre o teclado.
    setKeyboard(true);
  };

  const checkCodeOrder = () => {
    //Ao digitar sempre é pedido.
    handleScan(codeOrder);
  };

  const onChangeInput = (e) => {
    if (error) setError(false);
    setCodeOrder(e.target.value);
  };

  return (
    <React.Fragment>
      {/** MODAL */}
      <ModalComponent
        show={keyboard}
        onClickBackground={() => {
          setKeyboard(false);
          setError(false);
        }}
      >
        <div style={{ marginBottom: "2.5rem" }}>
          {strings.get("digite_o_numero_do_pedido_para_retirada")}
        </div>
        <InputComponent
          value={codeOrder}
          placeholder={strings.get("numero_do_pedido")}
          disabled
        />

        <div className="notification-slot">
          {error && (
            <center>
              <div className="text-danger text-smaller error-notification">
                {messageError}
              </div>
            </center>
          )}
        </div>

        <ButtonComponent
          state="btn btn-petz btn-full-width"
          loading={loading}
          click={checkCodeOrder}
          disabled={codeOrder == ""}
        >
          {strings.get("continuar")}
        </ButtonComponent>
      </ModalComponent>
      <ModalComponent
        show={popupError}
        onClickBackground={() => {
          setPopupError(false);
        }}
      >
        <div className="error-notification text-danger">{popupError}</div>

        <ButtonComponent state="btn btn-success" click={closeError}>
          {strings.get("continuar")}
        </ButtonComponent>
      </ModalComponent>
      {/** MODAL */}

      <section className="global-structure section4">
        {/** TOP AREA*/}
        <TopComponent>
          <BackIconComponent onClick={history.goBack} />
        </TopComponent>
        {/** TOP AREA*/}

        {/** CENTER AREA*/}
        <BlockComponent>
          <div>
            <SpacerComponent smaller>
              <div className="logo"></div>
            </SpacerComponent>
            <BlockComponent large>
              <SpacerComponent>
                <div className="text-center hello title-text">
                  <p>{strings.get("ola_tudo_bem")}</p>
                </div>
              </SpacerComponent>
            </BlockComponent>
            <SpacerComponent>
              <QrReader
                className="qrcoder"
                onError={handleError}
                onScan={handleScan}
                delay={300}
                style={{ width: "300px", height: "300px" }}
                facingMode="user"
              />
            </SpacerComponent>
            <SpacerComponent largest>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  margin: "1rem 4rem",
                }}
              >
                {strings.get("escaneie_o_qr_code")}
              </p>
            </SpacerComponent>
            {loading && <Spinner animation="border" />}
            <SpacerComponent largest>
              <div className="section2">
                <ButtonComponent
                  state="outline-secondary btn-text"
                  click={informPin}
                >
                  {strings.get("inserir_numero_do_pedido")}
                </ButtonComponent>
                <SpacerComponent>
                  <div className="text-smaller">
                    {strings.get("insira_o_numero_do_pedido_do_ecommerce")}
                  </div>
                </SpacerComponent>
              </div>
            </SpacerComponent>
          </div>
        </BlockComponent>
        {/** CENTER AREA*/}

        {/** BOTTOM AREA*/}
        <BottomComponent>
          <BlockComponent>
            <SpacerComponent>
              <HowItWorksComponent />
            </SpacerComponent>
          </BlockComponent>
        </BottomComponent>
        {/** BOTTOM AREA*/}
      </section>

      {/* KEYBOARD */}
      {keyboard && (
        <div className="keyboard-macro">
          <KeyboardComponent
            isSwitchable={false}
            onChange={setCodeOrder}
            onClose={() => {
              setKeyboard(false);
              setError(false);
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};
