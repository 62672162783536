
//add zeros left
export const pad = (n, len) => {
  return (new Array(len + 1).join('0') + n).slice(-len);
}

//returns date dd/mm/yyyy
export const getDate = () => {
  const d = new Date();
  return `${d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`;
}

//timer event para finalizar sessão.
export const timerEvent = history => {
  return {
    timer: 30,
    event: ()=> history.replace("/")
  };
}

//timer count down with event and finalEvent.
export const countDown = (duration, event = ()=>{}) => {
  let timer = duration, minutes, seconds;
  const interval = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      event({minutes, seconds, end: false});

      if (--timer < 0) {
          clearInterval(interval);
          event({end: true});
      }
  }, 1000);
  return interval;
}