import React, { useEffect } from "react";
import { useHistory } from "react-router";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import TopComponent from "../../components/TopComponent/TopComponent";
import CircleContainerComponent from "../../components/CircleContainerComponent/CircleContainerComponent";
import strings from "../../strings";

export default (props) => {
  const history = useHistory();

  useEffect(() => {
    props.lockerStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    var id = localStorage.getItem("@config-app/id");
    var token = localStorage.getItem("@config-app/merchant-id");
    var lockerCode = localStorage.getItem("@config-app/locker-code");
    if (!token || !id || !lockerCode) {
      history.push("/config");
    }
  }, []);

  const qrCode = () => {
    history.push("/qrcode");
  };

  return (
    <section className="global-structure section4" onClick={qrCode}>
      {/** TOP AREA*/}
      <TopComponent></TopComponent>
      {/** TOP AREA*/}

      {/** CENTER AREA*/}
      <BlockComponent>
        <div>
          <SpacerComponent>
            <img
              src="./logotipo_Petz.png"
              width="40%"
              alt="Logotipo Petz"
              style={{ margin: "5rem" }}
            />
          </SpacerComponent>
          <SpacerComponent largest></SpacerComponent>
          <SpacerComponent largest>
            <CircleContainerComponent isPulsing>
              <img
                src="./svg/clickHere.svg"
                alt="Toque na tela para começar"
                height={120}
              />
            </CircleContainerComponent>
          </SpacerComponent>
          <SpacerComponent large>
            <span className="text-large" style={{ margin: "7rem 0" }}>
              <b>
                <p>{strings.get("toque_na_tela_para_comecar")}</p>
              </b>{" "}
            </span>
          </SpacerComponent>
        </div>
      </BlockComponent>
      {/** CENTER AREA*/}

      {/* BOTTOM AREA */}
      <BottomComponent></BottomComponent>
      {/* BOTTOM AREA */}
    </section>
  );
};
