import React from "react";
import DoorComponent from "../DoorComponent/DoorComponent";
import DoorSecondaryComponent from "../DoorSecondaryComponent/DoorSecondaryComponent";

/**
 * @Name: Containers Locker
 * @Data: 2019
 * @Desc: Mostra todos as portas dentro do Locker.
 * @props:
 *     containers: Todas as instancias de Container(Door).
 *     onClickDoor: Ação de click em porta de container.
 *     currentDoor: Id da porta atual para ser aberta.
 *
 */
export default (props) => {
  const { onClickDoor, currentDoor, containers, isAllocation = false } = props;

  /*
        Cria um objeto com os status visuais para cada tipo de situação do container(Door).
        //Open
        //Available
        //Unavailable
        //Stocker (Fechado com produto)
    */
  const statusDoor = (number, status) => {
    //para facilitar, retorna apenas os positivos.
    //{ isOpen: true/false, available: true/false, done: true/false, canOpen: true/false};
    if (isAllocation) {
      if (number === currentDoor) return { isOpen: true, available: true };
    } else {
      if (currentDoor.some((i) => i == number)) {
        return { isOpen: true, available: true };
      } else {
        return { retrieved: true, available: true };
      }
    }
    switch (status) {
      case isAllocation ? "AVAILABLE" : "UNAVAILABLE":
        return { canOpen: true };
      case isAllocation ? "UNAVAILABLE" : "AVAILABLE":
        return {};
      // Porta Abastecida.
      default:
        return { supplied: true, canOpen: true };
    }
  };

  const clickMethod = (e, container) => {
    if (onClickDoor) {
      onClickDoor(e, container);
    }
  };

  console.log(containers);

  const DoorComponentToRender = isAllocation
    ? DoorSecondaryComponent
    : DoorComponent;

  return (
    <div className="group_mosaic_locker">
      {containers.length > 0 && (
        <div
          className={`${
            isAllocation ? "AllDoorsComponentIsAllocation" : "AllDoorsComponent"
          }`}
        >
          {containers.map((container) => {
            const { id, number, status } = container;

            if (isAllocation && container.status == "UNAVAILABLE") return null;

            return (
              <DoorComponentToRender
                key={id}
                {...statusDoor(number, status)}
                number={number}
                onClick={(e) => clickMethod(e, container)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
