import Container from "./Container";

export default class {

    constructor(data) {
        if(!data) return;

        this.id = data.id;
        this.code = data.code;
        this.active = data.active;
        this.containers = data.containers.map(c => new Container(c));
    }
}