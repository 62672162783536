import User from "./User";
import Container from "./Container";

export default class {

    constructor(data) {
        if(!data) return;

        //order and order-detail
        this.number = data.number;
        this.password = data.password;
        this.date = data.date;
        this.picked_up_date = data.picked_up_date;
        this.customer = new User(data.customer);

        //just order.
        this.status = data.status;
        this.id = data.id;
        this.containers = (data.containers||[]).map(c => new Container(c));
    }
}