import axios from "axios";
import config from "../config";

const headers = {
  token: config.token,
};
export default class BaseAPI {
  /**Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data, headers };
    return await axios.get(endpoint, requestConfig);
  }

  /**Métodos post */
  static async post(endpoint, data = {}) {
    return await axios.post(endpoint, data, { headers });
  }

  /**Método Put */
  static async put(endpoint, data = {}) {
    return await axios.put(endpoint, data, { headers });
  }

  /**Método Patch */
  static async patch(endpoint, data = {}) {
    return await axios.patch(endpoint, data, { headers });
  }

  /**Método delete */
  static async delete(endpoint, data = {}) {
    return await axios.delete(endpoint, { data }, { headers });
  }
}
