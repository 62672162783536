import React from 'react';

/** 
  * @Name: Show Ticket 
  * @Data: 2019
  * @Desc: Uma forma de destacar um pin, código ou algo do tipo
  * @props:
  *     

  */

const ShowTicketComponent = (props) =>{

    // Recebe as propriedades
    const{children} = props;

    return (
        <React.Fragment>
            <div className="ShowTicketComponent">
               {children}
            </div>
        </React.Fragment>
    )
}

export default ShowTicketComponent;