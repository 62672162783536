import React from "react";

/**
 * @Name: Botão Padrão
 * @Data: 2019
 * @Desc: Botão Padrão do Sistema.
 * @props:
 *
 *     className: 'Condição do botão (altera seu estilo)'
 *                 (state(primario, alerta, etc...), large, small, disabled, animate)
 *     disabled: true e false
 *     animate: true e false (ativa a animação padrão do botão)
 */

const ButtonComponent = (props) => {
  // Recebe as propriedades
  const {
    state,
    large,
    small,
    disabled,
    animate,
    children,
    click = null,
    hidden,
    type = "button",
    loading,
    icon,
    show = true,
  } = props;

  const values = `btn ${state ? "btn-" + state : "btn-primary"}${
    large ? " btn-lg" : ""
  }${small ? " btn-sm" : ""}${disabled ? " btn-disabled" : ""}${
    animate ? " animate" : ""
  }`;

  return (
    show && (
      <React.Fragment>
        <button
          className={values}
          onClick={!loading && !disabled && click ? click : null}
          hidden={hidden}
          type={type}
        >
          {loading ? (
            <span className="mini-loading">
              <span
                className="spinner-border spinner-border-sm "
                role="status"
                aria-hidden="true"
              ></span>
            </span>
          ) : icon ? (
            <React.Fragment>
              <div>
                <i className={icon} />
              </div>{" "}
              {children}
            </React.Fragment>
          ) : (
            children
          )}
        </button>
      </React.Fragment>
    )
  );
};

export default ButtonComponent;
