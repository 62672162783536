import React from "react";

/**
 * @Name: AlertInfoComponent
 * @Data: 2019
 * @Desc: Bottom do Sistema
 * @props:
 *
 */

const AlertInfoComponent = (props) => {
  // Recebe as propriedades
  const { children, show = true } = props;

  return (
    show && (
      <React.Fragment>
        <div className="alert_info">
          <div>
            {" "}
            <img
              src="./svg/info-circle.svg"
              alt="description"
              height={20}
              width={20}
            />
          </div>
          <div className="text-smaller">{children}</div>
        </div>
      </React.Fragment>
    )
  );
};

export default AlertInfoComponent;
