import React from "react";

/**
 * @Name: DoorComponent
 * @Data: 2019
 * @Desc: Porta que representada cada container para inserir produtos.
 */

const DoorComponent = (props) => {
  // Recebe as propriedades
  const { number, onClick, isOpen, supplied, retrieved, canOpen } = props;

  const classNameDoor = ` ${
    isOpen
      ? "container-open"
      : supplied
      ? "container-supplied"
      : retrieved
      ? "container-retrieved"
      : "container"
  }`;

  return (
    <React.Fragment>
      <div className="DoorComponent">
        <div className={classNameDoor} onClick={canOpen && onClick}>
          <div className="container-door-info">
            <img
              src="./svg/tutorial3.svg"
              alt="Porta do Locker"
              height={40}
              width={40}
            />
            <div className="door-number-text">Porta nº {number}</div>
          </div>
          <div className="container-door-status">
            {isOpen && (
              <span className="mini-loading">
                <span
                  className="spinner-border spinner-border-sm "
                  role="status"
                  aria-hidden="true"
                ></span>
              </span>
            )}
            {retrieved && (
              <>
                <div>Produto retirado</div>
                <img
                  src="./svg/check.svg"
                  alt="Porta do Locker"
                  height={40}
                  width={40}
                />
              </>
            )}
            {supplied && (
              <img
                src="./svg/check-rounded-bg.svg"
                alt="Porta do Locker"
                height={40}
                width={40}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DoorComponent;
