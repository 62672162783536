import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import HomePage from "./HomePage";
import ConfigPage from "./ConfigPage";
import QRCodePage from "./QRCodePage";
import OrderPage from "./OrderPage";
import SupplyPage from "./SupplyPage";
import SupplyOrderPage from "./SupplyOrderPage";
import ReversePage from "./ReversePage";
import ReverseOrderPage from "./ReverseOrderPage";
import MenuSupplierPage from "./MenuSupplierPage";
import { observer } from "mobx-react";

//homepage 1 page OK
//qrcode   1 page Ok
//order/   1 page OK

//reverse  2 pages ReversePage
//--->/order       ReverseOrderPage

//supply   2 pages SupplyPage
//--->/order       SupplyOrderPage

export default (props) => {
  return (
    <div>
      <Router>
        <Route exact path="/" render={() => <HomePage {...props} />} />
        <Route exact path="/qrcode" render={() => <QRCodePage {...props} />} />
        <Route exact path="/config" render={() => <ConfigPage {...props} />} />
        <PrivateRoutes {...props} />
        <Redirect to="/" />
      </Router>
    </div>
  );
};

//rotas privadas que é possível acessar apenas com usuário logado.
const PrivateRoutes = observer((props) => {
  if (!props.lockerStore.getUser()) return null;

  return (
    <React.Fragment>
      <Route exact path="/order" render={() => <OrderPage {...props} />} />
      <Route
        exact
        path="/menusupplier"
        render={() => <MenuSupplierPage {...props} />}
      />
      <Route exact path="/supply" render={() => <SupplyPage {...props} />} />
      <Route
        exact
        path="/supplyorder"
        render={() => <SupplyOrderPage {...props} />}
      />
      <Route exact path="/reverse" render={() => <ReversePage {...props} />} />
      <Route
        exact
        path="/reverseorder"
        render={() => <ReverseOrderPage {...props} />}
      />
    </React.Fragment>
  );
});
