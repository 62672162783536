import React from "react";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import strings from "../../strings";

/**
 * @Name: HowItWorksComponent
 * @Data: 2019
 * @Desc: Informação de como funciona.
 * @props:
 *
 */

const HowItWorksComponent = (props) => {
  return (
    <section className="HowItWorks">
      <div className="text-left">
        <SpacerComponent>
          <div className="text-center">{strings.get("veja_como_funciona")}</div>
        </SpacerComponent>
        <SpacerComponent>
          <div className="box-tutorial">
            <div>
              {" "}
              <img
                src="./svg/tutorial2.svg"
                alt="Imagem ilustrativa QR Code"
              ></img>{" "}
            </div>
            <p className="text-smaller">
              {strings.get(
                "Informe_o_numero_do_pedido_do_ecommerce_utilizando_o_teclado"
              )}
            </p>
          </div>

          <div className="box-tutorial">
            <div>
              {" "}
              <img src="./svg/tutorial3.svg" alt="Porta do Locker" />
            </div>
            <p className="text-smaller">
              {strings.get(
                "Toque_nas_portas_que_estão_disponiveis_para_a_retirada_do_seu_pedido"
              )}
            </p>
          </div>
        </SpacerComponent>
      </div>
    </section>
  );
};

export default HowItWorksComponent;
