import axios from "axios";
import { pad } from "../utils/methods";
import config from "../config";
import md5 from "md5";
import { url_locker_mock } from "../utils/constants";
const BASE_URL = config.urllocker;

export default class LocalAPI {
  //BASE/locker/check/01/01/hash?serial_port=/dev/ttyACM0
  static async checkOpenDoor(id, serialPort) {
    const result = await axios.get(LocalAPI.mountURL("check", id), {
      params: { serial_port: serialPort },
    });
    return LocalAPI.isOpen(result.data);
  }

  //BASE/locker/open/01/01/hash?serial_port=/dev/ttyACM0
  static async openDoorCommand(id, serialPort) {
    const result = await axios.get(LocalAPI.mountURL("open", id), {
      params: { serial_port: serialPort },
    });
    return LocalAPI.isOpen(result.data);
  }

  //return true to open and false to close.
  static isOpen(data) {
    const { status } = Array.isArray(data) ? data[0] : data;
    return status.slice(-1) === "O"; //or C de Close
  }

  //create hash to get value.
  static get hash() {
    const key = "123456";
    const word = "LOCKERYS";
    return md5(`${key}${word}`).toLowerCase();
  }

  // mount url.
  static mountURL(action, id) {
    if (BASE_URL === url_locker_mock) {
      return `${BASE_URL}/${action}`;
    } else {
      return `${BASE_URL}/locker/${action}/01/${pad(id, 2)}/${LocalAPI.hash}`;
    }
  }
}
