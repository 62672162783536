import React from "react";
import SpacerComponent from "../SpacerComponent/SpacerComponent";
import strings from "../../strings";

/**
 * @Name: HowItsEasyComponent
 * @Data: 2019
 * @Desc: Informação de como é facil retirar pedidos.
 * @props:
 *
 */

const HowItsEasyComponent = (props) => {
  return (
    <div className="text-left">
      <SpacerComponent>
        <div className="text-center btn-text" style={{ fontSize: "2rem" }}>
          {strings.get("veja_como_e_facil")}
        </div>
      </SpacerComponent>
      <SpacerComponent>
        <div className="box-tutorial">
          <div>
            {" "}
            <img
              src="./svg/tuto1.svg"
              alt="description"
              height={64}
              width={64}
            />{" "}
          </div>
          <p className="text-smallest">
            {strings.get("Selecione_a_porta_para_retirar_os_seus_produtos")};
          </p>
        </div>

        <div className="box-tutorial">
          <div>
            <img
              src="./svg/tuto3.svg"
              alt="description"
              height={64}
              width={64}
            />{" "}
          </div>
          <p className="text-smallest">
            {strings.get("Retire_os_seus_produtos_e_feche_a_porta")};
          </p>
        </div>

        <div className="box-tutorial">
          <div>
            <img
              src="./svg/tuto2.svg"
              alt="description"
              height={64}
              width={64}
            />
          </div>
          <p className="text-smallest">
            {strings.get(
              "Selecione_a_próxima_porta_até_completar_as_retiradas"
            )}
            ;
          </p>
        </div>
      </SpacerComponent>
    </div>
  );
};

export default HowItsEasyComponent;
