import React from "react";

/** 
  * @Name: Bloco Padrão
  * @Data: 2019
  * @Desc: Bloco Padrao do Sistema. 
  * @props:
  *     

  */

const BlockComponent = (props) => {
  // Recebe as propriedades
  const {
    children,
    large,
    small,
    medium,
    largest,
    smaller,
    styleType = "type1",
    animate,
    style,
  } = props;

  const values = `${large ? "block-large" : ""}${small ? "block-small" : ""} ${
    medium ? "block-medium" : ""
  }${largest ? "block-largest" : ""}${smaller ? "block-smaller" : ""}${
    animate ? " animate" : ""
  }`;

  return (
    <React.Fragment>
      <div className={`BlockComponent ${styleType}`}>
        <div className={`block ${values} `} style={style}>
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockComponent;
