import LocalAPI from "../services/LocalAPI";
import { extendObservable } from "mobx";

// Store to communicate with the local API..
export default class DoorsStore {
  interval = null;

  constructor() {
    extendObservable(this, {
      currentDoor: null,
      openDoors: [],
      openDoorsIntervals: [],
      currentNumberSerial: null,
      currentDoorSerialPort: null,
    });
  }

  // check if is current door with open status.
  async checkOpenDoor(
    isRetrieveOrder,
    number,
    currentNumberSerial,
    currentDoorSerialPort
  ) {
    if (!this.currentDoor && !isRetrieveOrder) return;

    number = number ?? this.currentDoor;
    currentNumberSerial = currentNumberSerial ?? this.currentNumberSerial;
    currentDoorSerialPort = currentDoorSerialPort ?? this.currentDoorSerialPort;

    try {
      const result = await LocalAPI.checkOpenDoor(
        currentNumberSerial,
        currentDoorSerialPort
      );
      if (!result) {
        if (!isRetrieveOrder) {
          this.currentDoor = null;
          this.currentNumberSerial = null;
          this.currentDoorSerialPort = null;
        } else {
          this.openDoors = this.openDoors.filter((e) => e !== number);
        }
        this.setIntervalOff(
          this.openDoorsIntervals.find((i) => i.number == number)?.interval ??
            null
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  async openDoorCommand(container) {
    try {
      await LocalAPI.openDoorCommand(
        container.numberSerial,
        container.serialPort
      );
      this.currentDoor = container.number;
      this.currentNumberSerial = container.numberSerial;
      this.currentDoorSerialPort = container.serialPort;

      this.delay();

      await this.checkOpenDoor(
        false,
        container.number,
        container.numberSerial,
        container.serialPort
      );

      this.setIntervalOn();
      return true;
    } catch (err) {
      console.log("err", err);
    }
    return false;
  }

  // Interval Turn ON to ckechOpenDoor
  setIntervalOn() {
    if (this.interval) {
      this.setIntervalOff();
    }
    this.interval = setInterval(() => this.checkOpenDoor(), 1000);
  }

  async openDoorCommandRetrieveOrder(container) {
    try {
      await LocalAPI.openDoorCommand(
        container.numberSerial,
        container.serialPort
      );

      this.delay();

      await this.checkOpenDoor(
        true,
        container.number,
        container.numberSerial,
        container.serialPort
      );

      this.openDoors.push(container.number);
      var interval = setInterval(
        () =>
          this.checkOpenDoor(
            true,
            container.number,
            container.numberSerial,
            container.serialPort
          ),
        1000
      );

      this.openDoorsIntervals.push({ number: container.number, interval });
      return true;
    } catch (err) {
      console.log("err", err);
    }
    return false;
  }

  // Interval Turn OFF to ckechOpenDoor
  setIntervalOff(interval) {
    clearInterval(interval ?? this.interval);
    this.interval = null;
  }

  // Delay method
  delay(time = 1000) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
