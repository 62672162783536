import React from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from 'react-router'
import strings from "../../strings";
import TopComponent from "../../components/TopComponent/TopComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import BackIconComponent from "../../components/BackIconComponent/BackIconComponent";

export default observer(props => {
    const history = useHistory();

    const { lockerStore } = props;
    const { user } = lockerStore;

    const onClickReverse = () => {
        history.push("/reverse");
    }

    const onClickSupply = () => {
        history.push("/supply"); 
    }

    return (<React.Fragment>
        <section className="global-structure section2">
            <TopComponent>
                <BackIconComponent onClick={() => history.replace("/")}/>
            </TopComponent>

            <BlockComponent>
                <div>
                    <SpacerComponent>
                        <div className="text-left">
                            {`${strings.get("seja_bem_vindo")} ${user ? user.name : ''}`}
                        </div>
                    </SpacerComponent>

                    <SpacerComponent largest>
                        <div className="p-2">
                        <ButtonComponent
                            icon="fas fa-recycle"
                            state="outline-secondary size-large"
                            click={onClickReverse}> 
                            {strings.get("reversa")}
                        </ButtonComponent>
                        </div>
                        
                        <div className="p-2">
                        <ButtonComponent 
                            icon="fas fa-gas-pump"
                            state="outline-secondary size-large"
                            click={onClickSupply}> 
                            {strings.get("abastecimento")}
                        </ButtonComponent>
                        </div>
                    </SpacerComponent>
                
                </div>
            </BlockComponent>

            <BottomComponent>
            </BottomComponent>
        </section>
    </React.Fragment>);
});