import React from "react";

/**
 * @Name: Título Padrão
 * @Data: 2019
 * @Desc: Título Padrão do Sistema.
 * @props:
 *
 */

const TitleHeaderComponent = (props) => {
  // Recebe as propriedades
  const { children, back } = props;

  return (
    <React.Fragment>
      <div className="__TitleHeaderComponent_Container">
        {back ? (
          <span className="__TitleHeaderComponent_Back">{back}</span>
        ) : null}
        {children}
      </div>
    </React.Fragment>
  );
};

export default TitleHeaderComponent;
