import React from 'react';

/** 
  * @Name: Comp Espaço
  * @Data: 2019
  * @Desc: Espaçamento entre componente como um comp. 
  * @props:
  *     

  */

const SpacerComponent = (props) =>{

    // Recebe as propriedades
    const{children, smaller, small, large, largest} = props;

    const values = 
        `${large ? "space-large" : ""}${small ? "space-small": ""}${largest ? "space-largest" : ""}${smaller ? "space-smaller": ""}`;


    return (
        <React.Fragment>
        <div className={`spacer ${values} `}>
            {children}
        </div>
        </React.Fragment>
    )
}

export default SpacerComponent;