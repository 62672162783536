import LockerStore from "./LockerStore";
import DoorsStore from "./DoorsStore";

class RootStore {

	constructor() {
		this.lockerStore = new LockerStore();
		this.doorsStore = new DoorsStore();
	}
}

export default new RootStore();