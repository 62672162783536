import React from "react";

/**
 * @Name: Input Component
 * @Data: 2019
 * @Desc: Input Component
 * @props:
 *
 */

const InputComponent = (props) => {
  // Recebe as propriedades
  const { placeholder, value, onChange, onFocus, ...otherProps } = props;

  return (
    <React.Fragment>
      <input
        type="text"
        className="input-global"
        // placeholder={placeholder}
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        {...otherProps}
      />
    </React.Fragment>
  );
};

export default InputComponent;
