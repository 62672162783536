import { extendObservable } from "mobx";

export default class {
  constructor(data) {
    if (!data) return;

    this.id = data.id;
    this.number = data.number;
    this.kind = data.kind;
    this.serialPort = data.serial_port;
    this.numberSerial = data.number_serial;

    extendObservable(this, {
      status: data.status,
    });
  }
}
