import React from 'react';

/** 
  * @Name: BottomComponent
  * @Data: 2019
  * @Desc: Bottom do Sistema
  * @props:
  *        
  */

const BottomComponent = (props) => {
    // Recebe as propriedades
    const { children } = props;

    
    return (
        <React.Fragment>
            <div className="BottomComponent">{children}</div>
        </React.Fragment>
    )
}

export default BottomComponent;