import React, { useState } from "react";

import strings from "../../strings";

import ButtonComponent from "../ButtonComponent/ButtonComponent";

/**
 * @Name: Tabela Reversa Padrão
 * @Data: 2019
 * @Desc: Tabela Reversa Padrão do Sistema.
 * @props:
 *
 */

const TableReversaComponent = (props) => {
  // Recebe as propriedades
  const { itens, user, buttonHandler = null } = props;

  const [selectedItem, setSelectedItem] = useState();

  const handleReverseButton = () => {
    buttonHandler(selectedItem);
  };

  return (
    <React.Fragment>
      <div>
        <div className="__TableReversaComponent_Header">
          <span className="text-featured">
            {strings.get("ola")}
            {` ${user ? user : ""}`}
          </span>
          <span className="reserva">{`Hoje temos ${itens.length} reversa(s)`}</span>
        </div>

        <div className="__TableReversaComponent_Table">
          {itens.map((item, index) => {
            return (
              <div
                key={`tableReversa_${index}`}
                className={selectedItem === item ? "selected" : ""}
                onClick={(i) => setSelectedItem(item)}
              >
                {item.number}
              </div>
            );
          })}
        </div>
        <ButtonComponent
          state="btn btn-full-width btn-petz"
          click={handleReverseButton}
          disabled={!selectedItem}
        >
          Fazer reversa
        </ButtonComponent>
      </div>
    </React.Fragment>
  );
};

export default TableReversaComponent;
