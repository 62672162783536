import React, { useState } from 'react';
import { Form } from '@unform/web';

import Input from '../../components/InputComponent2/InputComponent2';
import { useHistory } from 'react-router-dom';


export default (props) => {
  const [errors, setErrors] = useState(false)
  const history = useHistory();


  async function handleSubmit(data) {
    if (data.lockerId !== '' && data.merchant_id !== '' && data.locker_code !== '') {
      localStorage.setItem('@config-app/id', data.lockerId);
      localStorage.setItem('@config-app/merchant-id', `${data.merchant_id}`);
      localStorage.setItem('@config-app/locker-code', `${data.locker_code}`);
      setErrors(false)
      const check = await props.lockerStore.syncLocker();
      console.log(check)
      if (check && check.status === 200) {
        history.push('/')
      } else {
        setErrors(true)

      }
    } else {
      setErrors(true)
    }
  }


  return (
    <section className="global-structure section4" >
      <div className='config-block'>
        <Form onSubmit={handleSubmit} >
          <img src="./stoom.png" width='150px' alt="Stoom" />
          <Input name="lockerId" type="number" label="Locker Id" />
          <Input name="merchant_id" type="text" label="Merchant Id" />
          <Input name="locker_code" type="text" label="Codigo Locker" />
          {errors && <p className="error-msg">Ops! Alguma informação está incorreta</p>}
          <button type="submit">Salvar</button>
        </Form>
      </div>
    </section>
  );
}