import React, { useState } from "react";
import { useHistory } from "react-router";
import strings from "../../strings";
import { observer } from "mobx-react";
import KeyboardComponent from "../../components/Keyboard";
import TopComponent from "../../components/TopComponent/TopComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import { InfoContinue } from "../../components/ModalComponent/ModalComponent";
import InputComponent from "../../components/InputComponent/InputComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import BottomComponent from "../../components/BottomComponent/BottomComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import BackIconComponent from "../../components/BackIconComponent/BackIconComponent";

export default observer((props) => {
  const history = useHistory();
  const { lockerStore } = props;
  const { user } = lockerStore;

  const [value, onChange] = useState("");
  const [keyboard, setKeyboard] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState("");

  const onClickContinue = async () => {
    setLoading(true);
    const { success, error } = await lockerStore.externalOrderDetails(value);
    if (success) {
      //page doors.
      history.push("/supplyorder");
    } else {
      //popup error
      setModalError(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {/* MODAL */}
      <InfoContinue
        show={modalError}
        text={modalError}
        onClickContinue={() => setModalError("")}
      />
      {/* MODAL */}

      <section className="global-structure section1">
        {/* TOP AREA */}
        <TopComponent suply>
          <TitleHeaderComponent
            back={
              <BackIconComponent onClick={() => history.replace("/qrcode")} />
            }
          >
            {strings.get("modulo_de_abastecimento")}
          </TitleHeaderComponent>
        </TopComponent>
        {/** TOP AREA*/}
        {/* TOP AREA */}

        {/* BLOCK AREA */}
        <BlockComponent medium>
          <div className="text-center">
            <div className="text-featured">
              {" "}
              {strings.get("ola")}, {user && user.name}
            </div>
            <div className="text-small text-center">
              {strings.get("informe_pedido_para_abastecimento")}
            </div>
            <div className="space-block-large"></div>

            <SpacerComponent>
              <InputComponent
                value={value}
                onFocus={() => setKeyboard(true)}
                placeholder={strings.get("digite_o_número_do_pedido")}
                disabled
              />
            </SpacerComponent>
            <SpacerComponent>
              <ButtonComponent
                state="btn btn-petz btn-lg"
                click={onClickContinue}
                loading={loading}
                disabled={value == ""}
              >
                {strings.get("continuar")}
              </ButtonComponent>
            </SpacerComponent>
          </div>
        </BlockComponent>
        {/* BLOCK AREA */}

        {/* BOTTOM AREA */}
        <BottomComponent></BottomComponent>
        {/* BOTTOM AREA */}
      </section>

      {/* KEYBOARD */}
      {keyboard && (
        <div className="keyboard-macro">
          <KeyboardComponent
            isSwitchable={false}
            onChange={onChange}
            onClose={() => setKeyboard(false)}
          />
        </div>
      )}
      {/* KEYBOARD */}
    </React.Fragment>
  );
});
