import React from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import CountDownComponent from "../CountDownComponent/CountDownComponent";

/**
 * @Name: Modal
 * @Data: 2019
 * @Desc: Modal do Sistema
 * @props:
 *
 */

const ModalComponent = (props) => {
  // Recebe as propriedades
  const { children, show, onClickBackground } = props;

  return show ? (
    <React.Fragment>
      <div className="background-modal" onClick={onClickBackground}></div>
      <div className="grid-modal">
        <div className="modal-locker">
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

// Subcomponent to show info "OPEN DOOR".
const InfoOpenDoor = (props) => {
  const { onClickContinue, currentDoor } = props;
  return (
    <ModalComponent {...props}>
      <span>{`Feche a `}</span>
      <span className="text-detach">{`Porta nº ${currentDoor}`}</span>
      <span>{` para abrir a próxima porta.`}</span>
      <ButtonComponent
        state="btn btn-full-width btn-petz"
        click={onClickContinue}
      >
        {"Continuar"}
      </ButtonComponent>
    </ModalComponent>
  );
};

// Subcomponent to show info text simples.
const InfoContinue = (props) => {
  const { onClickContinue, text, timerEvent } = props;
  return (
    <ModalComponent {...props}>
      <span>{text}</span>
      {timerEvent ? <CountDownComponent {...timerEvent} /> : null}
      <ButtonComponent state="btn btn-lg  btn-petz" click={onClickContinue}>
        {"Continuar"}
      </ButtonComponent>
    </ModalComponent>
  );
};

const InfoDogContinue = (props) => {
  {
    const { onClickContinue, text, timerEvent } = props;
    return (
      <ModalComponent {...props}>
        <div className="d-flex flex-column align-items-center">
          <img
            src="./svg/dog.svg"
            alt="Porta do Locker"
            height={60}
            width={60}
            style={{ marginBottom: "3.5rem" }}
          />
          <span>{text}</span>
          {timerEvent ? <CountDownComponent {...timerEvent} /> : null}
        </div>

        <ButtonComponent state="btn btn-lg  btn-petz" click={onClickContinue}>
          {"Continuar"}
        </ButtonComponent>
      </ModalComponent>
    );
  }
};

const InfoConfirm = (props) => {
  const { onClickContinue, onClickCancel, text } = props;
  return (
    <ModalComponent {...props}>
      <span>{text}</span>
      <ButtonComponent state="btn btn-lg  btn-petz" click={onClickContinue}>
        {"Sim"}
      </ButtonComponent>
      <ButtonComponent state="btn btn-danger my-3" click={onClickCancel}>
        {"Não"}
      </ButtonComponent>
    </ModalComponent>
  );
};

export default ModalComponent;
export { InfoOpenDoor, InfoContinue, InfoConfirm, InfoDogContinue };
