import React from 'react';
import { countDown } from '../../utils/methods';

export default class extends React.Component {

    state = { timer: "00:00" };

    componentWillMount() {
        const { timer = 0 } = this.props;
        this.interval = countDown(timer, this.eventTimer) ;
    }

    eventTimer = (result) => {
        const { event } = this.props;
        const { minutes="00", seconds="00", end } = result;

        this.setState({ timer: `${minutes}:${seconds}` });
        if(end && event) {
            event();
        }
    };
    
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div>
                <span className="timer">
                    {this.state.timer}
                </span>
            </div>
        );
    }
}