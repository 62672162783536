import React from "react";

/**
 * @Name: CircleContainerComponent
 * @Data: 2023
 * @Desc: Container arredondado, com animacao de pulse caso true.
 * @props:
 *  - children: conteudo a renderizar
 *  - isPulsing: bool para indicar se deve ou nao realizar animacao
 */

const CircleContainerComponent = ({ children, isPulsing }) => {
  return (
    <div className={`CircleContainerComponent ${isPulsing ? "isPulsing" : ""}`}>
      {children}
    </div>
  );
};

export default CircleContainerComponent;
