export default class {

    constructor(data) {
        if(!data) return;

        this.id = data.id;
        this.type = data.type;
        this.name = data.name;
        this.email = data.email;
        this.cpf = data.cpf;
    }
}