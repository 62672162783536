
//url mock
export const url_locker_mock = "http://localhost:4001";


//PEDIDO
export const type_input_p = 'P';
//USER
export const type_input_u = 'U';

// return true when is PROD.
export const isProd = process.env.NODE_ENV !== "development";