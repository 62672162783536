import React, { useState } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react";
import TopComponent from "../../components/TopComponent/TopComponent";
import BlockComponent from "../../components/BlockComponent/BlockComponent";
import SpacerComponent from "../../components/SpacerComponent/SpacerComponent";
import AllDoorsComponent from "../../components/AllDoorsComponent/AllDoorsComponent";
import strings from "../../strings";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import BackIconComponent from "../../components/BackIconComponent/BackIconComponent";
import {
  InfoOpenDoor,
  InfoContinue,
  InfoConfirm,
  InfoDogContinue,
} from "../../components/ModalComponent/ModalComponent";
import TitleHeaderComponent from "../../components/TitleHeaderComponent/TitleHeaderComponent";
import { timerEvent } from "../../utils/methods";

export default observer((props) => {
  const history = useHistory();
  const { lockerStore, doorsStore } = props;
  const { currentDoor } = doorsStore;
  const { externalOrder, user } = lockerStore;

  const [modalOpenDoor, setModalOpenDoor] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalEnd, setModalEnd] = useState(false);
  const [modalNoAllocation, setModalModalNoAllocation] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickDoor = async (e, container) => {
    if (container.number === currentDoor) return;
    setLoading(true);
    await doorsStore.checkOpenDoor();

    setModalOpenDoor(currentDoor);

    if (!currentDoor) {
      await doorsStore.openDoorCommand(container);
      lockerStore.allocateContainer(container.number);
    }
    setLoading(false);
  };

  const onClickFinish = async () => {
    if (!lockerStore.allocatedDoors().length) {
      setModalModalNoAllocation(true);
    } else {
      setLoading(true);
      const result = await lockerStore.allocate();
      if (result) {
        setModalEnd(true);
      } else {
        setModalError(true);
      }
      setLoading(false);
    }
  };

  const onClickCancelSupply = async () => {
    setLoading(true);
    //console.log("LENGTH -> ", LockerStore.allocatedDoors().length);
    //if(LockerStore.allocatedDoors().length > 0){
    /*for (let i = 0; i < lockerStore.allocatedDoors().length; i++) {
            await doorsStore.openDoorCommand(lockerStore.allocatedDoors()[i].id);
        }*/
    //}

    setModalEnd(true);
    setLoading(false);
    history.replace("/");
  };

  const returnPage = () => {
    lockerStore.resetOrder();
    history.goBack();
  };

  const containers = lockerStore.locker ? lockerStore.locker.containers : [];

  const haveAvalibleContainers = () => {
    let list = containers.filter(
      (container) => container.status === "AVAILABLE"
    );
    return list.length > 0;
  };

  return (
    <React.Fragment>
      {/** MODAL AREA*/}
      <InfoOpenDoor
        show={modalOpenDoor && currentDoor}
        currentDoor={currentDoor}
        onClickContinue={() => setModalOpenDoor(false)}
      />

      <InfoContinue
        show={modalError}
        text="Erro ao finalizar abastecimento."
        onClickContinue={() => setModalError(false)}
      />

      <InfoDogContinue
        timerEvent={timerEvent(history)}
        show={modalEnd}
        text="Abastecimento feito com sucesso."
        onClickContinue={() => history.replace("/")}
      />

      <InfoContinue
        show={modalNoAllocation}
        text="Não há portas alocadas para este pedido."
        onClickContinue={() => setModalModalNoAllocation(false)}
      />

      <InfoConfirm
        show={modalConfirm}
        text="Tem certeza que deseja cancelar o abastecimento?"
        onClickContinue={() => onClickCancelSupply()}
        onClickCancel={() => setModalConfirm(false)}
      />

      {/** MODAL AREA */}

      <section className="global-structure section1">
        {/** TOP AREA*/}
        <TopComponent suply>
          <TitleHeaderComponent
            back={<BackIconComponent onClick={returnPage} />}
          >
            {strings.get("abastecimento_do_pedido")}{" "}
            {externalOrder && externalOrder.number}
          </TitleHeaderComponent>
        </TopComponent>
        {/** TOP AREA*/}

        {/* BLOCK AREA */}
        <BlockComponent large>
          <block className="text-left">
            <SpacerComponent>
              <span className="text-featured">
                {strings.get("ola")} {user ? user.name : ""}
              </span>
            </SpacerComponent>
            <SpacerComponent>
              {haveAvalibleContainers ? (
                <div className="text-small text-center">
                  {strings.get("selecione_uma_ou_mais_portas")}
                </div>
              ) : (
                <div className="text-small text-center">
                  Não possui armários vazios
                </div>
              )}
            </SpacerComponent>
            <SpacerComponent>
              <AllDoorsComponent
                containers={containers}
                currentDoor={currentDoor}
                onClickDoor={onClickDoor}
                isAllocation
              />
            </SpacerComponent>
            <SpacerComponent>
              <ButtonComponent
                state="btn-petz btn-full-width"
                click={onClickFinish}
                loading={
                  lockerStore.allocatedDoors().length &&
                  (currentDoor || loading)
                }
                disabled={
                  !lockerStore.allocatedDoors().length || currentDoor || loading
                }
              >
                {strings.get("finalizar_abastecimento")}
              </ButtonComponent>
            </SpacerComponent>
          </block>
        </BlockComponent>
        {/* BLOCK AREA */}

        <SpacerComponent></SpacerComponent>
      </section>
    </React.Fragment>
  );
});
