import React from 'react';

/** 
  * @Name: Botão Padrão
  * @Data: 2019
  * @Desc: Botão Padrão do Sistema. 
  * @props:
  *     
  *     className: 'Condição do botão (altera seu estilo)'
  *                 (state(primario, alerta, etc...), large, small, disabled, animate)
  *     disabled: true e false
  *     animate: true e false (ativa a animação padrão do botão)    
  */

const TopComponent = (props) => {
    // Recebe as propriedades
    const { children , suply, reverse} = props;

    
    return (
        <React.Fragment>
            <div className={`TopComponent ${suply || reverse ? 'manager-mode': ''}`}>{children}</div>
        </React.Fragment>
    )
}

export default TopComponent;