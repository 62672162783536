import React, { useState } from "react";
import "react-simple-keyboard/build/css/index.css";
import Keyboard from "react-simple-keyboard";

const KeyboardComponent = (props) => {
  const { onChange, isSwitchable } = props;
  const keys = ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 -"];
  const alphaNumericKeyboard = [
    "A B C D E",
    "F G H I J",
    "K L M N O",
    "P Q R S T",
    "U V W X Y Z",
    "{bksp} {switchToNumeric}",
  ];
  const numericKeyboard = [
    "1 2 3",
    "4 5 6",
    "7 8 9",
    "{bksp} 0 {switchToAlpha}",
  ];
  const [layoutName, setLayoutName] = useState("numeric");
  const layout = isSwitchable
    ? { numeric: keys }
    : { numeric: numericKeyboard, alpha: alphaNumericKeyboard };

  const onKeyPress = (button) => {
    if (button === "{switchToAlpha}" || button === "{switchToNumeric}")
      handleSwitch();
  };

  const handleSwitch = () => {
    setLayoutName(layoutName === "numeric" ? "alpha" : "numeric");
  };

  return (
    <React.Fragment>
      <Keyboard
        layoutName={layoutName}
        onChange={onChange}
        onKeyPress={onKeyPress}
        layout={layout}
        display={{
          "{bksp}": "Apagar",
          "{switchToAlpha}": "Aa",
          "{switchToNumeric}": "123",
        }}
        theme="stoom-keyboard-theme"
      />
    </React.Fragment>
  );
};

export default KeyboardComponent;
