import React from "react";

/**
 * @Name: Back Icon
 * @Data: 2019
 * @Desc: Melhoria para não replicar className em N páginas.
 * @props:
 *     onClick: Clique em botão voltar.
 */
export default ({ onClick }) => (
  <img
    src="./svg/back.svg"
    alt="back"
    style={{ margin: "2rem" }}
    onClick={onClick}
  />
);
