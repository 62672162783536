import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

/** 
  * @Name: Input Component to Unform
  * @Data: 2020
  * @Desc: Input Component to Unform
  * @props:
  *        
  */
export default function InputComponent2({ name, label, ...rest }) {

  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className="inputWrapper"
    >
      <label htmlFor={fieldName}>{label}</label>
      <input
        id={fieldName}
        ref={inputRef}
        className={error ? 'has-error' : ''}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
}